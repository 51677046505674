import { ShoeSizeSystem, UnitSystem } from '@/types/frontend/enums/languageEnums';

const app = {
    products: {
        title: 'Produits',
        new: 'Nouveau',
        all: 'Tous les produits',
        sizes: '',
        excludeVAT: 'HT',
        startDesign: 'Commencer le design',
        searchResults: 'Résultats de recherche pour &quot;<strong>{query}</strong>&quot;',
        noResults: 'Aucun produit trouvé',
        showInShop: 'Afficher le produit dans votre magasin',
        connectProduct: 'Connecter le produit TiB',
        connectProductId: 'ID produit Shopify',
    },
    tibProductConnect: {
        error: {
            title: "Le produit n'a pas pu être connecté",
            variantNotFound: "Les variantes suivantes n'ont pas pu être trouvées:\n",
        },
        success: {
            title: 'Produit connecté avec succès',
        },
    },
    mainShop: {
        title: 'Mon magasin',
    },
    orders: {
        title: 'Commandes',
        personalized: 'Commandes personnalisées',
        notEu: 'Commandes hors UE',
        personalizedShort: 'Personnalisé',
        notEUShort: 'Non-UE',
        all: 'Tous',
        splittedOrders: 'Une partie de cette commande:',
    },
    myDesignLibrary: {
        title: 'Mes designs',
        deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce design?',
    },
    resources: {
        title: 'Ressources',
        blog: 'Blog Print-On-Demand',
        whatsappChannel: 'Chaîne WhatsApp',
        productAndDeliverInformation: 'Informations sur le produit et la livraison',
        productImages: 'Images du produit',
        helpAndFaqs: 'Aide & FAQ',
    },
    deliveryInformation: 'Info de livraison',
    account: {
        title: 'Compte',
        invoices: 'Factures',
        settings: 'Paramètres',
    },
    pagination: {
        prev: 'Précédent',
        next: 'Suivant',
        further: 'Suivant',
        create: 'Créer',
    },
    search: {
        placeholder: 'Rechercher',
    },
    sort: {
        value: 'Trier',
    },
    filter: {
        label: 'Filtrer',
    },
    creditCard: {
        error: {
            title: "La carte de crédit n'a pas pu être enregistrée",
            tokenContent: 'Informations de carte de crédit invalides',
            backendContent: "Erreur lors de l'enregistrement des données",
        },
        success: {
            title: 'Enregistré avec succès',
            content: 'Votre carte de crédit a été enregistrée avec succès',
        },
    },
    welcomeBlock: {
        title: `Bienvenue {username}`,
        suggest: 'Explorez notre portfolio de produits et créez votre produit unique en moins de 2 minutes.',
        subtitle:
            "--- Vous trouverez les problèmes connus à jour <a target='_blank' rel='noopener noreferrer' href='https://help.marketprint.de/known-issues/'>ici</a>. ---<br><br>La tant attendue <b>MarketPrint 2.0</b> La mise à jour est enfin en ligne ! Nous avons changé tout le frontend pour vous offrir une meilleure expérience utilisateur.<br><br>S'il y a encore des petits problèmes dans les deux premières semaines, veuillez nous appeler au <b>+49 8261 7939101</b>.<br>Ou laissez votre avis et vos suggestions pour les futures innovations à : <b>feedback@marketconsultive.de</b><br><br>Nous vous souhaitons beaucoup de plaisir avec le nouveau design et sommes impatients de vous entendre ! Nous sommes loin d'avoir terminé et vous remercions pour votre fidélité.<br><br>- Toute l'équipe de <i>MarketConsultive GmbH</i><br><br>PS : Quelques fonctionnalités passionnantes, comme votre propre bibliothèque de designs, seront mises à votre disposition dans les prochaines semaines ! Consultez <a target='_blank' rel='noopener noreferrer' href='https://www.marketprint.de'>www.marketprint.de</a> ! De plus, nous prévoyons un portfolio complet de produits d'au moins 20 nouveaux best-sellers qui seront mis en ligne dans les prochaines semaines et mois.",
        cta: 'Créez un produit maintenant',
    },
    productsIntro: {
        title: 'Notre gamme de produits',
    },
    currentOrders: {
        title: `Commandes en cours:`,
        more: 'Afficher plus...',
    },
    currentOrdersModal: {
        shopifyId: 'ID Shopify',
        trackingNumber: 'Numéro(s) de suivi',
        moreInfo: "Plus d'infos",
        paymentMethod: 'Méthode de paiement',
        transactionNumber: 'Numéro de transaction',
        ordered: 'Commandé',
        inProcess: 'En production',
        shipped: 'Expédié',
        cancelled: 'Annulé',
        refunded: 'Remboursé',
        yes: 'Oui',
        no: 'Non',
        btnUploadDesigns: 'Télécharger les designs',
        btnReleaseForProduction: 'Approuver pour production',
        btnViewOrder: 'Afficher la commande',
        btnUpdateCustomerData: "Mettre à jour l'adresse",
        updateCustomerData: 'Mettre à jour les données du client',
        btnDownloadInvoice: 'Télécharger la facture',
        textReleaseForProduction: 'Voulez-vous vraiment approuver cette commande pour la production?',
        orderReady: 'La commande a été approuvée pour la production!',
        orderReadyErrorTitle: "La commande n'a pas pu être approuvée pour la production!",
        orderReadyError: 'Vérifiez si tous les designs sont complets!',
        btnUploadInvoices: 'Télécharger la facture',
        invoice: 'Facture',
        selectVariant: 'Choisir une variante',
        selectImage: 'Design personnalisé',
        confirmationUpload: 'Téléchargement réussi!',
        notPayed: 'Le paiement à MarketPrint a échoué. Veuillez réessayer dans les paramètres ou contacter notre support.',
        noPreview: "Pas d'aperçu disponible pour les mugs actuellement! \n Vous pouvez toujours télécharger le design.",
        for: 'pour',
        btnRetryPayment: 'Réessayer le paiement',
        uploaded: 'Designs téléchargés',
        downloadInvoice: 'Télécharger la facture',
        noInvoice: 'Facture non disponible',
        noMockups: 'Aucun mockup disponible pour ce produit',
        btnCancel: 'Annuler la commande',
        cancelReason: "Raison de l'annulation",
        textCancel: 'Êtes-vous sûr de vouloir annuler cette commande?',
        orderCancelErrorTitle: "La commande n'a pas pu être annulée",
        orderCancelled: 'La commande a été annulée',
        orderCancelError: 'La commande peut déjà être en production ou expédiée.',
    },
    checklist: {
        title: `Votre liste de contrôle`,
        subtitle: `Il ne reste que quelques étapes pour configurer votre magasin!`,
        installApp: `Installer l'application MarketPrint`,
        createProduct: `Créer un produit`,
        paymentMethod: `Sélectionner un moyen de paiement`,
    },
    todoOrders: {
        title: `Vos tâches à faire`,
        subtitle: `Complétez vos commandes`,
        order: `Téléchargez la facture pour la commande <strong>#{id}</strong>`,
    },
    sortOptions: {
        relevance: 'Pertinence',
        newArrivals: 'Nouveautés',
        bestSelling: 'Meilleures ventes',
        priceLowHigh: 'Prix croissant',
        priceHighLow: 'Prix décroissant',
        ratings: 'Avis',
    },
    home: {
        bestseller: 'Meilleures ventes actuelles de MarketPrint',
        newProducts: 'Nouveaux produits',
    },
    ordersIntro: {
        orders: 'Aperçu des commandes',
        personalized: 'Commandes personnalisées',
        notEu: 'Commandes hors UE',
    },
    design: {
        front: 'Avant',
        back: 'Arrière',
        left: 'Gauche',
        right: 'Droite',
        colors: 'Couleurs',
        size: 'Taille',
        sizes: 'Tailles variées',
        design: 'Conception',
        countries: 'Pays',
        germany: 'Allemagne',
        austria: 'Autriche',
        switzerland: 'Suisse',
        landArea: 'Pays / Zone',
        alternativeDesign: 'Conception alternative',
        alternativeDesignColors: 'Conception alternative pour les couleurs suivantes',
        productDescription: 'Description du produit',
        downloadProductData: 'Télécharger la fiche produit et les maquettes',
        designNote: 'Notes de création de conception',
        sizeChart: 'Tableau des tailles',
        insertGraphic: 'Insérer un graphique',
        important: `<span>Important:</span> Placez votre conception à l'intérieur du marqueur extérieur vert. Si vous dépassez les lignes, votre conception ne sera pas complètement imprimée sur le produit.`,
        productTitle: 'Titres du produit',
        productTitlePlaceholder: 'Ajouter un titre',
        productDescriptionPlaceholder: 'Ajouter une description',
        doubleSidedPrint: 'Impression multi-faces - déjà incluse',
        purchasePriceVat: "Prix d'achat (hors TVA)",
        purchasePrice19Vat: "Prix d'achat (TVA incluse 19%)",
        sellingPrice19VAt: 'Prix de vente (TVA incluse 19%)',
        profitMargin: 'Votre marge bénéficiaire (brute)',
        price: 'Prix',
        shippingCostsOverview: 'Aperçu des frais de port',
        shippingPrice: 'Prix de livraison',
        additionalPricePerProduct: 'Un supplément de 1,00 € sera facturé par produit supplémentaire dans la commande',
        addSizeChart: 'Ajouter un tableau des tailles à la boutique',
        publishProduct: 'Publier le produit sur Shopify',
        chooseMockup: 'Choisissez votre maquette',
        titleLibrary: 'Votre bibliothèque de conceptions',
        addDesignLibrary: `Télécharger une nouvelle conception`,
        personalProduct: 'Ceci est un produit personnalisable',
        tibProductFront: "Optimiser l'avant",
        tibProductBack: "Optimiser l'arrière",
        tibProductSleeveLeft: 'Optimiser la manche gauche',
        tibProductSleeveRight: 'Optimiser la manche droite',
        tibProductLeft: 'Optimiser la gauche',
        tibProductRight: 'Optimiser la droite',
        tibProductFrontLeft: "Optimiser l'avant gauche",
        tibProductFrontRight: "Optimiser l'avant droit",
        tibProductBackLeft: "Optimiser l'arrière gauche",
        tibProductBackRight: "Optimiser l'arrière droit",
        tibProductSideLeft: 'Optimiser côté gauche',
        tibProductSideRight: 'Optimiser côté droit',
        tibProductAll: 'Tout optimiser',
        quality: {
            good: 'Bon',
            normal: 'Moyen',
            bad: 'Mauvais',
        },
        productCreated: 'Produit créé',
        productCreateError: "Le produit n'a pas pu être créé",
        maxOne: "Un maximum d'un graphique",
        infoCanvas: `<span>Placement:</span> Côté avant de la toile à l'intérieur du cadre gris foncé,
            côté
            Chevauchement jusqu'à la ligne verte.`,
        infoPoster: `<span>Placement:</span> Placement des
            conceptions jusqu'à la ligne verte extérieure. La zone grise
            est coupée pendant la production.`,
        infoGlas: `<span>Pas de placement:</span> Pour les travaux de gravure, nous ne fournissons <b>aucun</b> placement pour
            disponible, veuillez modifier vos graphiques vectoriels en conséquence puis charger l'image
            à nouveau pour ajuster le placement.<br><br>
            <span>Rapport d'aspect fixe:</span> Assurez-vous que votre SVG a la <b>largeur
            {printMediumWidth} </b> et la <b>hauteur {printMediumHeight}</b> .<br>
            Alternativement, définissez la largeur et la hauteur de manière à ce qu'elles aient le même rapport d'aspect que <b> {printMediumAspectRatio} </b>.<br><br>
            <span>Un seul fichier de conception:</span> Il ne peut y avoir <b>qu'un seul</b> graphique vectoriel pour la conception
            être utilisé.`,
        disabledStep2: `Pour passer à l'étape suivante, vous devez choisir: Couleur, Taille et Télécharger l'image`,
        disabledStep3: `Pour passer à l'étape suivante, vous devez spécifier le nom du produit, la description et le prix`,
        uploadErrorMessageBeer:
            "Le rapport d'aspect SVG ne correspond pas au rapport d'aspect du support d'impression (largeur/hauteur = 1,67). Veuillez réessayer avec un fichier correspondant !",
        uploadErrorMessageWine:
            "Le rapport d'aspect SVG ne correspond pas au rapport d'aspect du support d'impression (largeur/hauteur = 1). Veuillez réessayer avec un fichier correspondant !",
        uploadErrorTitle: 'Erreur de téléchargement',
        sleeveRight: 'Manche droite',
        sleeveLeft: 'Manche gauche',
        frontLeft: 'Avant gauche',
        frontRight: 'Avant droit',
        backLeft: 'Arrière gauche',
        backRight: 'Arrière droit',
        sideLeft: 'Côté Gauche',
        sideRight: 'Côté Droit',
        combinedDesignTitle: 'Même conception sur tous les côtés',
        combinedDesignDescription: 'Désactivez cette option si vous souhaitez avoir des conceptions différentes sur la pantoufle gauche et droite',
        designDownload: 'Téléchargement de la conception',
    },
    accountSettings: {
        title: 'Paramètres',
        subtitle: 'Gérez les paramètres de votre profil.',
        contactInfo: 'Coordonnées',
        paymentInfo: 'Informations de paiement',
        email: 'E-Mail',
        tel: 'Téléphone',
        billingAddress: 'Adresse de facturation',
        senderAddress: "Adresse de l'expéditeur",
        paymentServiceProvider: 'Service de paiement',
        billingAgreement: 'Accord de facturation',
        deposited: 'Déposé',
        changePaymentMethod: 'Changer le mode de paiement',
        tryAgain: 'Réessayer',
        notifications: 'Notification',
        preferredLanguage: 'Langue préférée',
        preferredLanguageDescription:
            "Lorsque vous êtes connecté à MarketPrint, c'est la langue que vous voyez.<br/>Vous pouvez changer la langue à tout moment.",
        productLanguage: 'Ajouter des produits dans la boutique',
        productLanguageDescription:
            "Ici, vous pouvez changer la langue de votre produit et l'unité de mesure du tableau des tailles lors de l'importation dans votre boutique.",
        apiToken: 'Jeton API',
        apiTokenDescription:
            "Ici, vous pouvez générer un jeton API à utiliser avec différents services. Après avoir généré votre jeton, vous ne pouvez le voir qu'une seule fois. Veuillez le sauvegarder dans un endroit sûr. Vous pouvez toujours générer un nouveau jeton, ce qui invalidera l'ancien.",
        generate: 'Générer',
        regenerate: 'Régénérer',
        popup: 'Veuillez autoriser la fenêtre pop-up pour stocker vos données de paiement',
        notificationsCheckbox: {
            failedPayment: "Recevoir une notification en cas d'échec de paiement",
            personalizedDesign: "Recevoir une notification lorsqu'un design personnalisé doit être téléchargé",
            tips: 'Souhaitez-vous recevoir des conseils et des informations de MarketPrint par E-Mail?',
        },
        editBillingAddress: "Modifier l'adresse de facturation",
        address: {
            title: 'Adresse',
            firstName: 'Prénom',
            lastName: 'Nom',
            address1: 'Adresse 1',
            address2: 'Adresse 2',
            company: 'Entreprise',
            phone: 'Téléphone',
            email: 'E-Mail',
            name: 'Nom / Entreprise',
            address1Street: 'Rue',
            address1Number: 'Numéro de maison',
            city: 'Ville',
            zip: 'Code postal',
            countryName: 'Pays',
            validate: {
                zipWarning: {
                    title: 'Code postal invalide!',
                    content: "Ce code postal n'est pas valide. Veuillez vérifier votre saisie.",
                },
            },
            noChanges: 'Veuillez apporter des modifications avant de sauvegarder',
            vatId: 'ID TVA',
        },
        language: 'Langue:',
        units: 'Unités:',
        shoeSizes: 'Tailles de chaussures:',
        editInvoiceAddressToasts: {
            success: {
                content: 'Votre adresse de facturation a été enregistrée avec succès.',
            },
            unavailable: {
                title: 'Avertissement',
                content:
                    "Nous n'avons pas pu vérifier votre numéro de TVA pour le moment, mais votre adresse a été enregistrée. Veuillez réessayer plus tard.",
            },
            invalid: {
                content: "Le numéro de TVA fourni n'est pas enregistré.",
            },
            format: {
                content: "Le numéro de TVA fourni n'a pas le bon format. Veuillez vérifier votre saisie.",
            },
            germanVat: {
                title: 'Erreur',
                content: "Un numéro de TVA allemand n'est pas autorisé.",
            },
        },
        save: 'Enregistrer',
        editSenderAddress: "Modifier l'adresse de l'expéditeur",
        general: "Changer d'adresse",
        selectPayment: 'Veuillez choisir',
        paypal: 'PayPal',
        creditCard: 'Carte de crédit',
        notDeposited: 'Non déposé',
        deposit: 'Faire un dépôt',
        paymentError: 'Mode de paiement non disponible',
        tib: 'TeeInBlue',
        connectTibProductInfo: 'Connectez votre produit TiB à MarketPrint',
        saveIntegrationApiInfosInfo: "Enregistrez vos informations API TiB pour pouvoir recevoir des mises à jour d'expédition dans TeeInBlue",
        webhookUrl: 'URL du Webhook',
        stockAvailabilityTitle: 'Disponibilité des stocks',
        stockAvailabilityCheckbox: 'Mettre à jour automatiquement la disponibilité des stocks',
        stockAvailabilityDescription:
            "Si cette option est cochée, vos produits sont automatiquement marqués comme épuisés dès qu'ils ne sont plus disponibles chez nos fournisseurs ou que le produit a été abandonné par le fabricant. <br> <br>Si non cochée, les produits seront toujours affichés comme disponibles même si le produit est abandonné ou en rupture de stock chez nos fournisseurs.",
    },
    myProducts: {
        title: 'Mes produits',
    },
    noResults: {
        text: "Il n'y a pas encore de commandes disponibles",
        products: "Il n'y a pas encore de produits disponibles dans cette catégorie",
    },
    shared: {
        yes: "D'accord",
    },
    invoices: {
        title: 'Factures',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        download: 'Télécharger le PDF',
        downloadCSV: 'Télécharger le CSV',
        annotation: 'Le téléchargement peut prendre un certain temps',
        noResults: "Il n'y a pas de factures pour cette période.",
    },
    login: {
        install1: `Se connecter<br>à `,
        install2: '',
        shopUrl: 'Entrez un domaine Shopify (NOMDEBOUTIQUE.myshopify.com):',
        redirect: 'Redirigé...',
        login: 'Connexion',
        howToLogin: 'Comment puis-je me connecter?',
        marketprint: 'MarketPrint',
        company: 'MarketPrint - MarketConsultive GmbH',
        companyAddress: 'Allgäuerstraße 20, 87719 Mindelheim, Allemagne',
    },
    onboarding: {
        title: 'Bienvenue chez MarketPrint !',
        welcomeText: `Merci d'utiliser notre service d'impression à la demande !<br><br>
            Nous sommes une entreprise moderne et passionnée qui offre un service d'impression à la demande tout-en-un et fait tout pour offrir aux commerçants en ligne Shopify la meilleure et la plus facile expérience utilisateur dans le commerce en ligne.<br>
            Nous travaillons en permanence sur notre application et notre service pour rendre le modèle commercial de l'impression à la demande accessible à tous et vous offrir la possibilité d'accéder à un portefeuille de produits en constante expansion et à un processus de commande automatisé.<br><br>
            Nous sommes toujours ouverts aux suggestions d'amélioration concernant notre application Shopify. N'hésitez pas à nous appeler pour cela ou à écrire à notre support.<br><br>
            Amusez-vous avec l'application MarketPrint et faites de bonnes ventes,<br>
            L'équipe MarketPrint`,
        privacy:
            "J'ai lu les <a href='https://marketprint.de/agb/' target='blank'>Conditions Générales</a> et la <a href='https://marketprint.de/datenschutzerklarung-marketprint-app/' target='_blank'>Politique de Confidentialité</a> et je les accepte",
        emailNotifications: 'Je souhaite être informé par MarketPrint des nouveaux produits et des actualités par e-mail',
        formTitle: 'Très important pour un démarrage parfait :',
        formText: `Pour que vos commandes entrantes soient traitées automatiquement, vous devez entrer votre méthode de paiement. Nous proposons PayPal ou carte de crédit comme option et recommandons de déposer votre méthode de paiement et votre adresse de facturation dès maintenant pour un démarrage sans encombre.<br><br>
            Veuillez remplir le formulaire et choisir la méthode de paiement qui vous convient :`,
        billingAddress: "Ajouter l'adresse de facturation :",
        name: 'Nom :',
        namePlaceholder: 'Entrez votre nom',
        firstName: 'Prénom :',
        firstNamePlaceholder: 'Entrez le prénom',
        company: 'Entreprise (facultatif) :',
        companyPlaceholder: "Indiquer l'entreprise",
        addressStreet: 'Rue:',
        addressStreetPlaceholder: 'Ajouter la rue',
        addressNumber: 'Numéro de maison:',
        addressNumberPlaceholder: 'Ajouter le numéro de maison',
        postcode: 'Code postal :',
        postcodePlaceholder: 'Ajouter code postal',
        country: 'Pays :',
        countryPlaceholder: 'Sélectionner un pays',
        paymentMethod: 'Sélectionner un prestataire de services de paiement :',
        thankYouTitle: "Vous l'avez fait ! 🎉",
        thankYouText: `Vous avez tout fait et êtes prêt à créer et lancer votre propre marque.<br>
            Créons ensemble votre tout premier produit. Vous verrez à quel point c'est facile et rapide.<br><br>
            Essayez-le dès maintenant, cliquez simplement sur le bouton et créons votre premier produit directement avec l'un de nos best-sellers.`,
        thankYouNext: 'Commencez maintenant !',
        thankYouBack: 'Passer',
        city: 'Ville :',
        cityPlaceholder: 'Ajouter ville',
        error: {
            title: "Les informations du compte n'ont pas pu être enregistrées",
            backendContent: "Erreurs lors de l'enregistrement des données",
        },
    },
    success: {
        title: 'Succès',
    },
    error: {
        title: 'Erreur',
        content: "Une erreur s'est produite",
    },
    header: {
        notifications: 'Notifications',
    },
    countries: {
        Deutschland: 'Allemagne',
        Österreich: 'Autriche',
        Schweiz: 'Suisse',
        Andorra: 'Andorre',
        Belgien: 'Belgique',
        Bulgarien: 'Bulgarie',
        Dänemark: 'Danemark',
        Estland: 'Estonie',
        Finnland: 'Finlande',
        Frankreich: 'France',
        Griechenland: 'Grèce',
        Irland: 'Irlande',
        Italien: 'Italie',
        Kroatien: 'Croatie',
        Lettland: 'Lettonie',
        Luxemburg: 'Luxembourg',
        Monaco: 'Monaco',
        Niederlande: 'Pays-Bas',
        Norwegen: 'Norvège',
        Polen: 'Pologne',
        Portugal: 'Portugal',
        'San Marino': 'Saint-Marin',
        Schweden: 'Suède',
        Slowakei: 'Slovaquie',
        Slowenien: 'Slovénie',
        Spanien: 'Espagne',
        'Tschechische Republik': 'République Tchèque',
        Ungarn: 'Hongrie',
        Vatikanstadt: 'Vatican',
        'Vereinigtes Königreich': 'Royaume-Uni',
        Zypern: 'Chypre',
        Kosovo: 'Kosovo',
        Litauen: 'Lituanie',
        Malta: 'Malte',
        Rumänien: 'Roumanie',
        Albanien: 'Albanie',
        'Bosnien und Herzegowina': 'Bosnie-Herzégovine',
        Liechtenstein: 'Liechtenstein',
        Mazedonien: 'Macédoine',
        Moldau: 'Moldavie',
        Montenegro: 'Monténégro',
        Serbien: 'Serbie',
        'Vereinigte Staaten': 'États-Unis',
        'Zone 1': 'Zone 1',
        'Zone 2': 'Zone 2',
        'Zone 3': 'Zone 3',
    },
    euCountries: {
        AT: 'Autriche',
        BE: 'Belgique',
        BG: 'Bulgarie',
        CY: 'Chypre',
        CZ: 'Tchéquie',
        DE: 'Allemagne',
        DK: 'Danemark',
        EE: 'Estonie',
        EL: 'Grèce',
        ES: 'Espagne',
        FI: 'Finlande',
        FR: 'France',
        HR: 'Croatie',
        HU: 'Hongrie',
        IE: 'Irlande',
        IT: 'Italie',
        LT: 'Lituanie',
        LU: 'Luxembourg',
        LV: 'Lettonie',
        MT: 'Malte',
        NL: 'Pays-Bas',
        PL: 'Pologne',
        PT: 'Portugal',
        RO: 'Roumanie',
        SE: 'Suède',
        SI: 'Slovénie',
        SK: 'Slovaquie',
        GB: 'Royaume-Uni',
        CH: 'Suisse',
        NO: 'Norvège',
    },
    shippingTable: {
        canvas: 'Toile',
        poster: 'Affiche',
        basePrice: 'Prix de base',
        perAdditionalProduct: 'Par produit supplémentaire',
    },
    time: {
        today: "Aujourd'hui",
        nDaysAgo: 'Il y a {n} jours',
        '1DayAgo': 'Il y a 1 jour',
    },
    unitSystems: {
        [UnitSystem.METRIC]: 'Métrique',
        [UnitSystem.IMPERIAL]: 'Impérial',
    },
    shoeSizeSystem: {
        [ShoeSizeSystem.EU]: 'EU',
        [ShoeSizeSystem.US]: 'US',
        [ShoeSizeSystem.UK]: 'UK',
    },
};

export default app;
