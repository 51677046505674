import { defineStore } from 'pinia';
import { request } from '@/api-connectors/useBasicRequests';
import { AddressType, INTEGRATION_API_PARTNER, SettingsType } from '@/types/backend/settingsType';
import i18n, { useI18n } from '@/plugins/vue-i18n';
import { useProductLanguageStore } from '@/stores/languages';
import { Language, LanguageUnitMapping } from '@/types/frontend/enums/languageEnums';
import { useAffiliation } from '@/composables/useAffiliation';

interface SettingsStore {
    settings: SettingsType;
    invoiceAddress: AddressType;
    returnAddress: AddressType;
}

const getSettingsRoute = 'settings';
const getInvoiceAddressRoute = 'settings/getInvoiceAddress';
const getReturnAddressRoute = 'settings/getReturnAddress';
const setInvoiceAddressRoute = 'settings/setInvoiceAddress';
const setReturnAddressRoute = 'settings/setReturnAddress';
const setDefaultPaymentMethodRoute = 'settings/payment';
const retryPaymentRoute = 'settings/payment/retryPayment';
const setLocalizationRoute = 'settings/setLocalization/';
const setNotificationRoute = 'settings/notification';
const setStripePaymentIDRoute = 'settings/payment/update_stripe_payment_method_id';
const requestStripeSetupIntentRoute = 'settings/payment/requestStripeSetupIntent';
const setOnboardingFlowCompletedRoute = 'settings/setOnboardingFlowCompleted/';
const setAgbAcceptedRoute = 'settings/setAgbAccepted/';
const setNewsletterAcceptedRoute = 'settings/setNewsletterAccepted/';
const generateApiTokenRoute = 'settings/apiKey';
const updateIntegrationApiInfosRoute = 'settings/integrationApiInfos';
const autoAvailabilityUpdateRoute = 'settings/autoAvailabilityUpdate';

export const useSettingsStore = defineStore('settings', {
    state: (): SettingsStore => {
        return {
            settings: {} as SettingsType,
            invoiceAddress: {} as AddressType,
            returnAddress: {} as AddressType,
        };
    },
    actions: {
        async getSettings(): Promise<boolean> {
            const response = await request<SettingsType>({
                method: 'get',
                route: getSettingsRoute,
            });
            if (!response) return false;
            if (!response.integrationApiInfos) {
                response.integrationApiInfos = {};
            }
            for (const key of Object.values(INTEGRATION_API_PARTNER)) {
                if (!response.integrationApiInfos[key]) {
                    response.integrationApiInfos[key] = { webhookUrl: '' };
                }
            }
            this.settings = { ...response };

            //update language of browser
            if (!this.settings.preferredLanguage) {
                let language = i18n.locale as Language;
                if (!Object.values(Language).includes(language)) language = Language.EN;
                this.settings.preferredLanguage = language;
            }
            await this.setLocalizationDefaults(this.settings.preferredLanguage);
            const langStore = useProductLanguageStore();
            const { changeLanguage } = useI18n();
            langStore.setLocale(this.settings.preferredLanguage, this.settings.preferredUnitSystem, this.settings.preferredShoeSizeSystem);
            i18n.locale = this.settings.preferredLanguage;
            sessionStorage.setItem('locale', i18n.locale);
            changeLanguage(i18n.locale);

            return true;
        },
        async setLocalizationDefaults(language: Language) {
            //set default values if not set
            if (
                !this.settings.preferredUnitSystem ||
                !this.settings.preferredShoeSizeSystem ||
                !this.settings.productLanguage ||
                !this.settings.productUnitSystem ||
                !this.settings.productShoeSizeSystem
            ) {
                const systems = LanguageUnitMapping[language];
                this.settings.preferredLanguage = language;
                this.settings.preferredUnitSystem = this.settings.preferredUnitSystem ?? systems.unit;
                this.settings.preferredShoeSizeSystem = this.settings.preferredShoeSizeSystem ?? systems.shoeSize;
                this.settings.productLanguage = this.settings.productLanguage ?? this.settings.preferredLanguage;
                this.settings.productUnitSystem = this.settings.productUnitSystem ?? systems.unit;
                this.settings.productShoeSizeSystem = this.settings.productShoeSizeSystem ?? systems.shoeSize;
                await this.setLocalization();
            }
        },
        getUserFullName(): string {
            return this.settings.shop_address ? this.settings.shop_address.split('<br>')[0].trim() : (this.settings.shop_owner ?? '');
        },
        async getInvoiceAddress(): Promise<void> {
            const response = await request<AddressType>({
                method: 'get',
                route: getInvoiceAddressRoute,
            });

            if (!response) {
                this.invoiceAddress = {} as AddressType;
                return;
            }

            this.invoiceAddress = response;
        },
        async getReturnAddress(): Promise<void> {
            const response = await request<AddressType>({
                method: 'get',
                route: getReturnAddressRoute,
            });

            if (!response) {
                this.returnAddress = {} as AddressType;
                return;
            }

            this.returnAddress = response;
        },
        async setInvoiceAddress(address: AddressType & { vatId?: string }): Promise<string | undefined> {
            const response = await request<string>({
                method: 'put',
                route: setInvoiceAddressRoute,
                body: {
                    invoiceAddress: {
                        shop_owner: address.shop_owner,
                        street1: address.address1,
                        street1_street: address.address1_street,
                        street1_number: address.address1_number,
                        street2: '',
                        city: address.city,
                        zip: address.zip,
                        country: address.country_name,
                        countryCode: address.countryCode,
                    },
                    vatId: address.vatId,
                },
            });
            return response;
        },
        async setReturnAddress(address: AddressType): Promise<void> {
            const response = await request<string>({
                method: 'put',
                route: setReturnAddressRoute,
                body: {
                    returnAddress: {
                        shop_owner: address.shop_owner,
                        street1: address.address1,
                        street1_street: address.address1_street,
                        street1_number: address.address1_number,
                        street2: '',
                        city: address.city,
                        zip: address.zip,
                        country: address.country_name,
                    },
                },
            });

            if (response !== 'success') {
                return;
            }

            this.returnAddress = address;
        },
        async setDefaultPaymentMethod(method: string | undefined) {
            if (method) {
                const response = await request<SettingsType>({
                    method: 'post',
                    route: setDefaultPaymentMethodRoute,
                    body: {
                        default: method,
                    },
                });
                this.settings = response!;
            }
        },
        async requestStripeSetupIntent(id: string): Promise<{ confirmed: boolean; clientSecret?: string } | undefined> {
            return await request<{ confirmed: boolean; clientSecret?: string }>({
                method: 'post',
                route: requestStripeSetupIntentRoute,
                body: {
                    payment_method_id: id,
                },
            });
        },
        async setStripePaymentID(id: string): Promise<any> {
            return await request<any>({
                method: 'post',
                route: setStripePaymentIDRoute,
                body: {
                    payment_method_id: id,
                },
            });
        },
        async retryPayment(orderId: string | null = null): Promise<boolean> {
            const response = await request<string>({
                method: 'get',
                route: retryPaymentRoute,
                body: {
                    ...(orderId ? { orderId: orderId } : null),
                },
            });
            return response === 'OK';
        },
        async setLocalization() {
            await request<void>({
                method: 'patch',
                route: setLocalizationRoute,
                body: {
                    preferredLanguage: this.settings.preferredLanguage,
                    preferredUnitSystem: this.settings.preferredUnitSystem,
                    preferredShoeSizeSystem: this.settings.preferredShoeSizeSystem,
                    productLanguage: this.settings.productLanguage,
                    productUnitSystem: this.settings.productUnitSystem,
                    productShoeSizeSystem: this.settings.productShoeSizeSystem,
                },
            });
            const langStore = useProductLanguageStore();
            langStore.setLocale(this.settings.preferredLanguage, this.settings.preferredUnitSystem, this.settings.preferredShoeSizeSystem);
        },
        async setNotificationSettings(failedPayment: boolean, personalizedDesign: boolean) {
            const response = await request<SettingsType>({
                method: 'post',
                route: setNotificationRoute,
                body: {
                    failed_payment: failedPayment,
                    personalized_design: personalizedDesign,
                },
            });
            this.settings = response!;
        },
        async refreshSettings(): Promise<void> {
            await this.getSettings();
        },
        async refreshSettingsIfEmpty(): Promise<void> {
            if (!this.isSettingsLoaded) {
                await this.getSettings();
            }
        },
        async refreshAddresses(): Promise<void> {
            await this.getInvoiceAddress();
            await this.getReturnAddress();
        },
        async setOnboardingFlowCompleted(value: boolean) {
            return request<SettingsType>({
                method: 'patch',
                route: setOnboardingFlowCompletedRoute + value.toString(),
            }).then(() => {
                this.settings = {
                    ...this.settings,
                    onboardingFlowCompleted: value,
                };
            });
        },
        async setAgbAccepted(value: boolean) {
            const { getCurrentAffiliation, clearAffiliation } = useAffiliation();
            const affiliation = getCurrentAffiliation();

            return request<{
                settings: SettingsType;
                setAffiliatePartner: string | undefined;
            }>({
                method: 'patch',
                route: setAgbAcceptedRoute + value.toString(),
                body: {
                    affiliation: affiliation?.id,
                },
            }).then(response => {
                this.settings = {
                    ...this.settings,
                    agbAccepted: value,
                };

                //Delete set affiliation after it was successfully used
                if (response?.setAffiliatePartner && response.setAffiliatePartner === affiliation?.id) {
                    clearAffiliation();
                }
            });
        },
        async setNewsletterAccepted(value: boolean) {
            return request<SettingsType>({
                method: 'patch',
                route: setNewsletterAcceptedRoute + value.toString(),
            }).then(() => {
                this.settings = {
                    ...this.settings,
                    newsletterAccepted: value,
                };
            });
        },
        async setAutoAvailabilityUpdate() {
            return request<SettingsType>({
                method: 'patch',
                route: autoAvailabilityUpdateRoute + '/' + this.settings.autoAvailabilityUpdate,
            }).then(() => {});
        },
        async generateApiToken() {
            return request<string>({
                method: 'post',
                route: generateApiTokenRoute,
            }).then(response => {
                this.settings.apiToken = response;
            });
        },
        async updateIntegrationApiInfos() {
            return request<string>({
                method: 'patch',
                route: updateIntegrationApiInfosRoute,
                body: this.settings.integrationApiInfos,
            });
        },
    },
    getters: {
        shopDomain: state => {
            return state.settings.shop_domain;
        },
        onboardingFlowCompleted: state => {
            return state.settings.onboardingFlowCompleted;
        },
        agbAccepted: state => {
            return state.settings.agbAccepted;
        },
        isSettingsLoaded: state => {
            //needed to check on that key because only settings returned true all the time
            return !!state.settings?.shop_domain;
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
